<template>
  <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="exportData()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="what"
            :rules="[rules.required]"
            :items="dataOptions"
            item-text="name"
            item-value="value"
            :label="$t('exportLeadsWhichData')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="optin"
            :rules="[rules.required]"
            :label="$t('exportItemConfirmationText')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="exportData"
                :loading="fetchinData"
              >
                {{ $t("export") }}
              </v-btn>
              <v-btn v-else disabled>{{ $t("export") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)

export default {
  name: "ExportLeads",

  components: {
    //
  },

  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      what: null,
      isFormValid: false,
      optin: false,
      dataOptions: [
        {
          name: this.$t("All"),
          value: "all",
        },
        {
          name: this.$t("notContacted"),
          value: "notcontacted",
        },
        {
          name: this.$t("Scheduled"),
          value: "scheduled",
        },
        {
          name: this.$t("alreadyClient"),
          value: "client",
        },
        {
          name: this.$t("Lost"),
          value: "lost",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  props: ["campaignId"],

  created: function() {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );
  },

  methods: {
    exportData() {
      this.fetchinData = true;

      const that = this;

      const url =
        this.$apiBaseURL + "export/leads/" + this.campaignId + "/" + this.what;

      var request = new XMLHttpRequest();
      request.open("GET", url);
      request.setRequestHeader("Token", this.$store.getters["auth/token"]);
      request.responseType = "arraybuffer";
      request.onload = function() {
        const blob = new Blob([this.response], {
          type: "text/csv;charset=utf-8;",
        });

        const filename = that.campaignId + "_" + that.what + "_export.csv";

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
          that.$emit("closeDialog");
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          that.$emit("closeDialog");
        }
      };
      request.onerror = function(e) {
        console.log(e);
      };
      request.send();
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
