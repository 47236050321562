<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.campaignsStatistik')"
    />

    <v-row align="stretch" v-if="!fetchinData">
      <v-col class="col-12 col-md-6 col-lg-6">
        <CampaignInfoCard v-if="!fetchinData" :data="this.data.campaign[0]" />
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6">
        <ProcessingStatusCard
          v-if="!fetchinData"
          :data="data.campaign_members"
          :hasPay="data.campaign[0].page_has_mitfitpay"
        />
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6">
        <LeadDevelopmentCard
          v-if="!fetchinData"
          :data="data.campaign_members"
        />
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6">
        <GenderDistributionCard
          v-if="!fetchinData"
          :data="data.campaign_members"
        />
      </v-col>

      <v-col class="col-12" v-if="!fetchinData">
        <CampaignLeadsList
          :data="data.campaign_members"
          :hasPay="data.campaign[0].page_has_mitfitpay"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import CampaignInfoCard from "@/components/campaigns/old/CampaignInfoCard";
import ProcessingStatusCard from "@/components/campaigns/old/ProcessingStatusCard";
import LeadDevelopmentCard from "@/components/campaigns/old/LeadDevelopmentCard";
import GenderDistributionCard from "@/components/campaigns/old/GenderDistributionCard";
import CampaignLeadsList from "@/components/campaigns/old/CampaignLeadsList";

export default {
  name: "Campaign",

  components: {
    PageHeader,
    CampaignInfoCard,
    ProcessingStatusCard,
    LeadDevelopmentCard,
    GenderDistributionCard,
    CampaignLeadsList,
  },

  data() {
    return {
      pageTitle: null,
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  props: [],

  created: function () {
    // is a company changer allowed?
    this.$store.commit("companyChangeOption/SET_ALLOWED", false);

    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyID +
          "/legacy-campaigns/" +
          this.$route.params.id
      );
      this.data = response.data.data;
      this.pageTitle = this.data.campaign[0].page_title;
      this.fetchinData = false;
    },
  },

  mixins: [apiRequest],
};
</script>
