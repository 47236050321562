<template>
  <div>
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("leadDevelopment") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <LineChart
          :chartData="chartData"
          :chartLabels="chartLabels"
          :backgroundColor="backgroundColor"
          :chartLabel="chartLabel"
          v-if="dataProcessed"
        /><!-- wenn daten für chart aufbereitet wurden -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import LineChart from "@/components/charts/Line";
export default {
  name: "LeadDevelopmentCard",

  components: {
    LineChart,
  },

  data() {
    return {
      dataProcessed: false,
      chartData: [],
      chartLabels: [],
      backgroundColor: null,
      chartLabel: this.$t("leadDevelopment"),
      companyID: store.getters["auth/current_sid"],
    };
  },

  props: ["data"],

  created: function() {
    this.processDataForChart();
  },

  methods: {
    processDataForChart() {
      /* Aufbereiten der Daten für die Verwendung im Chart */

      const singleDatesArr = [];
      const datesArr = [];
      const countArr = [];
      let ascending = 0;

      this.data.forEach((item) => {
        let dateParts = item.games_data_participationdate.split(" "); // split date (uhrzeit weg)
        let justDate = dateParts[0]; // pure date (eg. 23.03.19)

        // push single dates (justDate) in array (if not already in)
        if (!singleDatesArr.includes(justDate)) {
          singleDatesArr.push(justDate);
        }

        // singleDatesArr -> justDate
        datesArr.push(justDate);
      });

      // build an array with date/count combi
      const combiArr = [];

      singleDatesArr.forEach((adate) => {
        let counter = 0;

        for (var c = 0; c < datesArr.length; c++) {
          if (datesArr[c] === adate) {
            ++counter;
          }
        }

        ascending = ascending + counter;
        countArr.push(ascending); // counter

        const dateCountArr = [];
        dateCountArr.push(adate);
        dateCountArr.push(counter);
        combiArr.push(ascending); // dateCountArr
      });

      this.chartData = countArr;
      this.chartLabels = singleDatesArr.reverse();
      (this.backgroundColor = "rgba(124,174,43,.5)"),
        (this.dataProcessed = true);
    },
  },
};
</script>
