<template>
  <v-card height="100%">
    <v-toolbar dark color="dark">
      <v-toolbar-title>{{ $t("info") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col class="col-12">
          <table class="default-table" v-if="data.id" style="width: 100%">
            <tbody>
              <tr>
                <td class="font-weight-bold">{{ $t("campaign") }}</td>
                <td>
                  {{ data.page_title }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">{{ $t("tool") }}</td>
                <td>
                  {{ data.page_game_type }}
                  <v-chip
                    v-if="
                      data.page_has_mitfitpay &&
                      data.page_has_mitfitpay !== '0' &&
                      data.page_has_mitfitpay != null &&
                      data.page_has_mitfitpay !== 0
                    "
                    small
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    <v-icon x-small left>mdi-credit-card</v-icon>
                    PAY
                  </v-chip>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">{{ $t("runtime") }}</td>
                <td>
                  {{ this.timestampToDate(data.page_startdate) }} -
                  {{ this.timestampToDate(data.page_enddate) }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">{{ $t("newLeads") }}</td>
                <td>{{ data.memberCount }} {{ $t("contacts") }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin
export default {
  name: "CampaignInfoCard",

  components: {
    //
  },

  data() {
    return {
      page_has_mitfitpay: null,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
    };
  },

  props: ["data"],

  created: function () {
    // mitfitPAY
    this.page_has_mitfitpay = this.data.game_has_mitfitpay;
    this.games_data_hasOrdered = this.data.games_data_hasOrdered;
    this.games_data_orderId = this.data.games_data_orderId;
  },

  methods: {
    //
  },

  computed: {
    //
  },

  mixins: [helpers],
};
</script>
