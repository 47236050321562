<template>
  <div>
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("genderDistribution") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <Doughnut
          :chartData="processedData"
          :chartLabels="chartLabels"
          :chartColors="chartColors"
          v-if="dataProcessed"
        /><!-- wenn daten für chart aufbereitet wurden -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import Doughnut from "@/components/charts/Doughnut";
export default {
  name: "GenderDistributionCard",

  components: {
    Doughnut,
  },

  data() {
    return {
      dataProcessed: false,
      chartLabels: [
        this.$t("Female"),
        this.$t("Male"),
        this.$t("Diverse"),
        this.$t("Unknown"),
      ],
      chartColors: ["#d7474a", "#478cc6", "#ac50ab", "#eeeeee"],
      companyID: store.getters["auth/current_sid"],
    };
  },

  props: ["data"],

  mounted() {
    /* @ mounted Hook, the computed properties are done with calculating.
    Problem was, that the chart was starting before the computed prop was ready */
    this.dataProcessed = true;
  },

  computed: {
    processedData() {
      /* Aufbereiten der Daten für die Verwendung im Chart */

      let maleCount = 0;
      let femaleCount = 0;
      let diverseCount = 0;
      let unknownCount = 0;

      this.data.forEach((item) => {
        if (item.person_sex === "male") {
          ++maleCount;
        } else if (item.person_sex === "female") {
          ++femaleCount;
        } else if (item.person_sex === "diverse") {
          ++diverseCount;
        } else {
          ++unknownCount;
        }
      });

      return [femaleCount, maleCount, diverseCount, unknownCount];
    },
  },

  methods: {
    //
  },
};
</script>
