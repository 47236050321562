<script>
/* Doughnut Chart
Props: chartData, chartColors, chartLabels (Arrays) 
*/
import { Doughnut } from 'vue-chartjs' /* https://vue-chartjs.org/guide/#browser */

export default {
  extends: Doughnut,
  data () {
    return {

      data: {
        labels: this.chartLabels,
        datasets: [{
					data: this.chartData,
					backgroundColor: this.chartColors,
					borderWidth: 1
				}]
      },

      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Doughnut Chart'
          }
        }
      },
    }

  }, 

  props: ['chartData','chartLabels','chartColors'],

  mounted () {
    
    this.renderChart(this.data, this.options)
  },

  watch: {

    chartData () {

      /* chartData has changed */
      this.data.datasets[0].data = this.chartData; // mutate the data
      this.renderChart(this.data, this.options); // rerender
      
  },

    
  }

}
</script>