<script>
/* Line Chart
Props: chartData, chartColors, chartLabels (Arrays) 
*/
import { Line } from 'vue-chartjs' /* https://vue-chartjs.org/guide/#browser */

export default {
  extends: Line,
  data () {
    return {

      data: {
        labels: this.chartLabels,
        datasets: [{
          label: this.chartLabel,
					data: this.chartData,
					backgroundColor: this.backgroundColor,
					borderWidth: 1
				}]
      },

      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart'
          }
        }
      },
    }

  },

  props: ['chartData','chartLabels','backgroundColor','chartLabel'],

  mounted () {
    this.renderChart(this.data, this.options)
  },

}
</script>